import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Office = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Office 365",
        href: slug,
        lang: "pl",
      }}
      header={{
        background: "linear-gradient( 45deg, red)",
        icons: "#eb3d01",
        navClass: "office-365",
        ogImage: require("../../assets/img/portfolio/office_365_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/office-365/",
      }}
    >
      <PortfolioHeader name="office_365" height="176" />
      <section className="container-fluid office_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Office 365</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania leadowa</li>
                <li>Banery reklamowe</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Na kampanię produktową składał się szereg działań. Tworzyliśmy
                stronę sprzedażową utworzona na potrzeby promocji produktu firmy
                Microsoft jakim jest Office365. Mimo, że leady z kampanii
                kierowane były do certyfikowanego partnera Microsoftu to same
                materiały brandowe musiały być zatwierdzone. Na początek
                współpracy otrzymaliśmy 90 stronicowy brand book dotyczący jak
                powinniśmy używać logo, ikon, sloganów, kolorystyki i wielu
                innych elementów identyfikacji wizualnej firmy Microsoft
                dotyczącego produktu Office 365 dla firm. Na tej podstawie
                przygotowaliśmy stronę Landing Page. Dzięki projektowi
                przygotowanemu użyciu zgodnemu z wytycznymi nie otrzymaliśmy ani
                jednej poprawki dotyczącej projektu Landing Page. Akceptacja
                odbyła się za pierwszym podejściem.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid office_section_3">
        <div className="row no-gutters row_1">
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Czym jest Landing Page?</h3>
              <p>
                Landing Page jest to pierwsza strona na którą trafia użytkownik
                na skutek podejmowanych przez firmę działań marketingowych.
                Strona docelowa może być stroną samodzielną (np. niezależną od
                strony firmowej) i przedstawiać jeden element oferty.
                Użytkownicy trafiają na Landing Page najczęściej po kliknięciu w
                banner, link w otrzymanym e-mailu, link sponsorowany lub inny
                link reklamowy. Landing Page jest naturalnym rozszerzeniem
                treści znajdujących się w reklamie. Głównym celem Landing Page
                jest konwersja w naszym przypadku pozyskanie firm, które pobiorą
                testowe klucze produktu Office 365 dla firm i przetestują
                produkt przez 60 dni. Celem pobocznym był zwiększenie liczby
                użytkowników którzy zapoznają się z filmami promocyjnymi Office
                365 dla firm.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/office_entire_page.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_2">
          <div className="col-md-6 image">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/office_preview.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Dlaczego strona Landing Page?</h3>
              <p>
                Strona Landing page ma jeden cel, nie ma podstron i kieruje
                użytkownika aby wykonał zaplanowaną przez nas akcję. Celem było
                pozostawienie przez użytkownika danych do kontaktu. Gdy
                użytkownik zostawił dane kontaktował się z nim certyfikowany
                partner z danego regionu w celu weryfikacji danych firmowych i
                przesłania odpowiedniej ilości kluczy.
              </p>
              <p>
                Film promocyjny pomagał wyjaśnić istotę rozwiązania jakie
                oferuje Office 365 dla firm. Strona produktu utworzona przez
                producenta ma zaplanowane inne cele i jest nastawiona na
                bezpośredni zakup bez możliwości przetestowania produktu.
              </p>
              <p>
                Kampania wyszła bardzo dobrze, a cele związane z ilością
                uruchomionych kluczy trialowych zostały zrealizowane.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Kampania promocyjna</h3>
              <p>
                Zajmowaliśmy się również kampanią promocyjną tego produktu w
                rejonie małopolski oraz wielkopolski. Na Landing Page
                kierowaliśmy użytkowników poprzez:
              </p>
              <ul>
                <li>
                  reklamy płatne na portalu społecznościowym Facebooku kierowane{" "}
                  <br />
                  do grup docelowych
                </li>
                <li>płatną promocję filmu Office 365 na Youtube</li>
                <li>email marketing do firm oraz szkół publicznych</li>
                <li>promocję banerową na portalach branżowych</li>
                <li>promocję linków sponsorowanych w Google AdWords</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 image">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/office_banners.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid office_section_testimonial">
        <div className=" office_section_testimonial__wrapper">
          <p>
            W ramach opinii z firmy Microsoft otrzymaliśmy informację <br />{" "}
            <em>
              „Z naszej strony jesteśmy zadowoleni z ilości wygenerowanych
              pobrań i leadów, ale teraz czekamy na sprzedaż, która jest dla nas
              głównym wyznacznikiem sukcesu”
            </em>
          </p>
          <p>
            Finalizacją i wdrożeniami zajmował się bezpośrednio certyfikowany
            partner
          </p>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Office;
